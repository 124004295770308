import { useEffect } from 'react'
import tw, { styled } from 'twin.macro'
import slugify from '@sindresorhus/slugify'
import parse from 'html-react-parser'

import FlexSection from '@components/FlexSection'
import { Accordion, AccordionItem } from '@components/Accordion'

import { componentLoaded, findColors } from '@utils/functions'

import {
  WpPage_Acfpagefields_Content_Faq,
  WpPrograma_Acfpagefields_Content_Faq
} from '@_types/graphql-types'

type contentType =
  | WpPage_Acfpagefields_Content_Faq
  | WpPrograma_Acfpagefields_Content_Faq

const Faq = ({
  data,
  index,
  tabs
}: {
  data: contentType
  index: string
  tabs?: boolean
}) => {
  const { questions, title, bgColor, tagTitle } = data

  const sectionColors = findColors(bgColor)

  useEffect(() => {
    componentLoaded(title)
  }, [])

  return (
    <FlexSection
      index={index}
      title={title}
      tagTitle={tagTitle}
      colors={sectionColors}
      alignTitle="center"
      id={title || ''}
      paddingBottom
      paddingTop
      tabs={tabs}
    >
      {questions && questions.length > 0 && (
        <FaqWrapper tw="max-w-4xl mx-auto">
          <Accordion>
            {questions.map(({ question, answer }) => {
              const id = slugify(question)
              return (
                <AccordionItem key={id} id={id} title={question}>
                  <div tw="!leading-relaxed prose !w-full max-w-full!">
                    {parse(answer)}
                  </div>
                </AccordionItem>
              )
            })}
          </Accordion>
        </FaqWrapper>
      )}
    </FlexSection>
  )
}

const FaqWrapper = styled.div`
  & > div {
    ${tw`mb-8`}
  }

  h3 {
    ${tw`relative text-lemann-red font-medium text-xl mb-2`}
  }
`

export default Faq
